import './App.css'
import { Container, CssBaseline } from '@mui/material'
import Header from './Header'
import { Route, Routes } from 'react-router-dom'
import Events from '../../features/events/Events'
import Participants from '../../features/events/Participants'
import Register from '../../features/events/Register'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CreateEvent from '../../features/events/CreateEvent'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

const theme = createTheme({
    typography: {
        fontFamily: "'Roboto', sans-serif",
    },
    palette: {
        primary: {
            main: '#1185b8',
            light: '#5AABCE',
            dark: '#045173',
        },
        secondary: {
            // main: '#FF3B0D',
            main: '#FF623C',
            light: '#FF8568',
            dark: '#B62300',
        },
    },
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CssBaseline />
                <Header />
                <Container>
                    <Routes>
                        <Route path='/' element={<Events />} />
                        <Route path='/events' element={<Events />} />
                        <Route path='/events/create' element={< CreateEvent />} />
                        <Route path='/events/:id' element={<Participants />} />
                        <Route path='/events/:id/register' element={<Register />} />
                    </Routes>
                </Container>
            </LocalizationProvider>
        </ThemeProvider>
    );
}

export default App;
