
interface Props {
    date: string;
}

export default function EventDateComponent({ date }: Props) {

    const isValidDate = (d: any): d is Date => {
        return d instanceof Date && !isNaN(d.getTime());
    };

    const formatDateWithTime = (date: Date | string) => {
        let d = (typeof date === 'string') ? new Date(date) : date;

        if (!isValidDate(d)) {
            console.error('Invalid date object:', date);
            return 'Invalid Date';
        }

        const dateOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
        const timeOptions: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit', hour12: true };

        return `${d.toLocaleDateString('en-US', dateOptions)} at ${d.toLocaleTimeString('en-US', timeOptions)}`;
    };
    
    function formatDateToCustom(dateString: string) {
        const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

        const dateObj = new Date(dateString);
        const day = dateObj.getUTCDate();
        const month = months[dateObj.getUTCMonth()];
        const year = dateObj.getUTCFullYear();
        const hours = dateObj.getUTCHours();
        const minutes = dateObj.getUTCMinutes().toString().padStart(2, '0'); // Ensure minutes are 2 digits

        const period = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12; // Convert to 12-hour format

        return `${day} ${month} ${year} @ ${formattedHours}:${minutes} ${period}`;
    }

    return (
        <span className="unbreakable-content">{formatDateWithTime(date)}</span>
    )
}

