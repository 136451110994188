import axios from 'axios';
import { useEffect, useState } from 'react'
import { SwapEvent } from '../../app/models/SwapEvent';
import { Container, Fab, Grid } from '@mui/material';
import EventCard from './EventCard';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';

export default function Events() {

    const [events, setEvents] = useState<SwapEvent[]>();

    useEffect(() => {
        axios.get("/api/GiftSwap/events")
            .then(response => setEvents(response.data))
            .catch(error => console.log(error));
    }, []);

    const handleAddClick = () => {
        // Handle the logic to add a new swap event
        console.log('Add new swap event clicked!');
    };

    return (
        <Container maxWidth='xl' sx={{ mt: 4, mb: 6 }}>
            <Link to="/events/create" style={{ textDecoration: 'none' }}>
                <Fab
                    color="primary"
                    aria-label="add"
                    style={{ position: 'fixed', bottom: '16px', right: '16px' }}
                >
                    <AddIcon />
                </Fab>
            </Link>
            <Grid container spacing={3}>
                {events && events.map(event => (
                    <Grid item xs={12} sm={6} md={4} key={event.id}>
                        <EventCard
                            event={event}
                        />
                    </Grid>
                ))}
            </Grid>
        </Container>
    )
}
