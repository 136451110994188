import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Hidden } from '@mui/material';
import { Participant } from '../../app/models/Participant';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

interface Props {
    participants: Participant[];
}

export default function ParticipantTable({ participants }: Props) {

    return (
        <>
            {/* Table for PC */}
            <Hidden only="xs">
                <TableContainer component={Paper}>
                    <Table sx={{ XminWidth: '650px' }}>
                        <TableHead>
                            <TableRow sx={{ background: "#CCC" }}>
                                <TableCell sx={{ fontWeight: 'bold', padding: '16px' }}>Name</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', padding: '16px' }}>Email</TableCell>
                                <TableCell align="center" sx={{ fontWeight: 'bold', padding: '16px' }}>Email Confirmed</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {participants.map(participant => (
                                <TableRow key={participant.id} sx={{ '&:nth-of-type(odd)': { background: "#f4f4f4" }, '&:hover': { background: "#e0e0e0" } }}>
                                    <TableCell sx={{ padding: '16px' }}>{participant.firstName} {participant.lastName}</TableCell>
                                    <TableCell sx={{ padding: '16px' }}>{participant.email}</TableCell>
                                    <TableCell align="center" sx={{ padding: '16px' }}>
                                        {participant.emailConfirmed ?
                                            <CheckCircleIcon style={{ color: 'green' }} /> :
                                            <CancelIcon style={{ color: 'red' }} />
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Hidden>

            {/* Stacked display for Mobile */}
            <Hidden only={['sm', 'md', 'lg', 'xl']}>
                {participants.map(participant => (
                    <Paper key={participant.id} sx={{ margin: '10px 0', padding: '10px' }}>
                        <div><strong>Name:</strong> {participant.firstName} {participant.lastName}</div>
                        <div><strong>Email:</strong> {participant.email}</div>

                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span><strong>Email Confirmed:</strong></span>
                            <span>
                                {participant.emailConfirmed ?
                                    <CheckCircleIcon style={{ color: 'green', marginLeft: '5px' }} /> :
                                    <CancelIcon style={{ color: 'red', marginLeft: '5px' }} />
                                }
                            </span>
                        </div>
                    </Paper>
                ))}
            </Hidden>

        </>
    )
}
