import { Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import AppTextInput from '../../app/components/AppTextInput';

export default function ConfirmEmailForm() {

    const { control } = useFormContext();
    
    return (
        <>
        <h4>Please enter One-Time PIN that was sent in an email.</h4>
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
                <AppTextInput control={control} name='otp' label='PIN' />
            </Grid>
        </Grid>
        </>
    )
}
