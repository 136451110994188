import { IconButton, Snackbar, Tooltip } from '@mui/material';
import { useState } from 'react';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { set } from 'react-hook-form';

interface Props {
    link: string;
}

export default function ShareLinkComponent({ link }: Props) {

    const [copied, setCopied] = useState<boolean>(false);
    // const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);

    const copyToClipboardFallback = (textToCopy: string) => {
        // Create a textarea
        const textarea = document.createElement('textarea');
        textarea.value = textToCopy;
        // Set it off-screen
        textarea.style.position = 'fixed';
        textarea.style.left = '-9999px';
        document.body.appendChild(textarea);

        // Focus and select the content
        textarea.focus();
        textarea.select();

        try {
            document.execCommand('copy');
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);  // Reset after 2 seconds
            // setSnackbarMessage("Copied!");
            // setTimeout(() => setSnackbarMessage(null), 2000);  // Reset after 2 seconds
        } catch (err) {
            console.error('Fallback copy to clipboard failed', err);
            // setSnackbarMessage("Failed to copy text!");
            // setTimeout(() => setSnackbarMessage(null), 2000);  // Reset after 2 secondsu
        }

        // Cleanup
        document.body.removeChild(textarea);
    };

    const copyToClipboard = () => {
        if (navigator && navigator.clipboard) {
            navigator.clipboard.writeText(link)
                .then(() => {
                    setCopied(true);
                    setTimeout(() => setCopied(false), 2000);  // Reset after 2 seconds
                    // setSnackbarMessage("Copied!");
                    // setTimeout(() => setSnackbarMessage(null), 2000);  // Reset after 2 seconds
                })
                .catch(err => {
                    console.error('Failed to copy text: ', err)
                    // setSnackbarMessage("Failed to copy text!");
                    // setTimeout(() => setSnackbarMessage(null), 2000);  // Reset after 2 seconds
                });
        } else {
            copyToClipboardFallback(link);
        }
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px', border: '1px solid #e0e0e0', borderRadius: '4px', overflow: 'hidden', width: '100%' }}>
            <input
                type="text"
                readOnly
                value={link}
                style={{
                    flexGrow: 1,
                    width: '100%',  // Set width to 100%
                    border: 'none',
                    padding: '8px 12px',
                    fontSize: '1rem',
                    lineHeight: '1.5',
                    color: '#333',
                    backgroundColor: '#f9f9f9',
                    outline: 'none'
                }}
            />
            <Tooltip title={copied ? "Copied!" : "Copy to clipboard"} placement="top">
                <IconButton size="small" onClick={copyToClipboard} style={{ backgroundColor: '#f3f3f3', borderRadius: 0 }}>
                    <FileCopyIcon />
                </IconButton>
            </Tooltip>
        </div>
    )
}

