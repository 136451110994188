import { Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import AppTextInput from '../../app/components/AppTextInput';

export default function RegisterForm() {

    const { control } = useFormContext();

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
                <AppTextInput control={control} name='firstName' label='First name' />
            </Grid>
            <Grid item xs={12} sm={12}>
                <AppTextInput control={control} name='lastName' label='Last name' />
            </Grid>
            <Grid item xs={12} sm={12}>
                <AppTextInput control={control} name='email' label='Email' />
            </Grid>
        </Grid>
    )
}
