import { LoadingButton } from '@mui/lab';
import { Box, Grid, Paper, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';


export default function CreateEvent() {

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [priceLimit, setPriceLimit] = useState('');
    const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(dayjs());
    const [error, setError] = useState<string | null>(null);

    const handleFormSubmit = async () => {

        setLoading(true);
        setError(null);

        const data = {
            name,
            priceLimit: Number(priceLimit) * 100,
            registrationDeadline: selectedDate?.toISOString()
        }

        try {
            const response = await fetch('/api/giftswap/events', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                console.warn(response);
                const errorData = await response.json();
                throw new Error(errorData.message || 'Network response was not ok');
            }

            const responseData = await response.json();
            console.log(responseData);

            navigate(`/events/${responseData.id}`);
            
        } catch (error) {
            console.error('There was an error!', error);
            if (error instanceof Error) {
                setError(error.message);
            } else {
                setError('An unexpected error occurred.');
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>

            <Typography component="h1" variant="h4" align="center">
                Create Event
            </Typography>

            {error && (
                <Typography component="p" variant="body2" color="error">
                    {error}
                </Typography>
            )}

            <form noValidate autoComplete="off" >

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            label="Name"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            label="Price Limit ($)"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={priceLimit}
                            onChange={(e) => setPriceLimit(e.target.value)}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={12}>
                    <DateTimePicker
                        label="Signup Deadline"
                        value={selectedDate}
                        onChange={(newDate) => setSelectedDate(newDate)}
                        sx={{ width: '100%', marginTop: '16px' }}
                    />
                </Grid>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <LoadingButton
                        loading={loading}
                        variant="contained"
                        type='submit'
                        sx={{ mt: 3, ml: 1 }}
                        onClick={handleFormSubmit}
                    >
                        Create
                    </LoadingButton>
                </Box>
            </form>
        </Paper >
    );
}
