import { TextField } from '@mui/material';
import { Control, useController, UseControllerProps } from 'react-hook-form';

interface AppTextInputProps {
    label: string;
    control: Control;
    name: string;
    multiline?: boolean;
    rows?: number;
    type?: string;
}

export default function AppTextInput(props: AppTextInputProps) {

    const { field, fieldState } = useController({ ...props, defaultValue: '' });

    return (
        <TextField
            {...props}
            {...field}
            fullWidth
            variant='outlined'
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
        />
    )
}
