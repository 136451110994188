import { Alert, Button, Container, Grid, Paper, useTheme } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SwapEvent } from '../../app/models/SwapEvent';
import ParticipantTable from './ParticipantTable';
import EventIcon from '@mui/icons-material/Event';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import Divider from '@mui/material/Divider';
import ShareIcon from '@mui/icons-material/Share';
import EventDateComponent from './EventDateComponent';
import ShareLinkComponent from './ShareLinkComponent';


export default function Participants() {

    const { id } = useParams();
    const [event, setEvent] = useState<SwapEvent>();
    const theme = useTheme();
    const baseUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    useEffect(() => {
        axios.get(`/api/GiftSwap/events/${id}`)
            .then(response => setEvent(response.data))
            .catch(error => console.log(error));
    }, [id]);

    const sendEmails = async () => {
        axios.get(`/api/GiftSwap/events/${id}/send-emails`)
            .then(response => {
                console.log("RESPONSE: ", response.data);
                setEvent(response.data);
                setErrorMessage(null);
            })
            .catch(error => {
                console.log(error);
                setErrorMessage(error?.response?.data?.title || "An error occurred while sending emails.");
            });
    }

    if (!event) return <></>

    console.log(event);

    return (
        <Container maxWidth='xl' sx={{ mt: 4, mb: 6 }}>
            <Grid container columnSpacing={2} rowSpacing={2}>
                <Grid item xs={12} sm={4}>
                    <Paper sx={{ p: 2, pt: 2, mt: 0 }}>
                        <h2><b>{event.name}</b></h2>

                        <Divider sx={{ marginY: 2 }} />

                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <EventIcon sx={{ color: theme.palette.primary.dark }} fontSize="medium" style={{ marginRight: '10px' }} />
                            <span><b>Sign-up by:</b> <EventDateComponent date={event.registrationDeadline} /></span>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                            <RequestQuoteIcon sx={{ color: theme.palette.primary.dark }} fontSize="medium" style={{ marginRight: '10px' }} />
                            <span><b>Price Limit:</b> ${event.priceLimit / 100}</span>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                            <ShareIcon sx={{ color: theme.palette.primary.dark }} fontSize="medium" style={{ marginRight: '10px' }} />
                            <div style={{ marginLeft: '0px', flexGrow: 1 }}>
                                <ShareLinkComponent link={`${baseUrl}/register`} />
                            </div>
                        </div>

                        <Divider sx={{ marginY: 2 }} />
                        {event.emailsSent ? (
                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px', width: '100%' }}>
                                <span><MarkEmailReadIcon sx={{ color: theme.palette.primary.dark }} fontSize="medium" style={{ marginRight: '10px' }} /></span>
                                <span><b>Emails Sent:</b> <EventDateComponent date={event.emailsSent} /></span>
                            </div>
                        ) : (
                            <>
                                {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                                    <Button
                                        variant="contained"
                                        sx={{ backgroundColor: theme.palette.secondary.main, '&:hover': { backgroundColor: theme.palette.secondary.dark, } }}
                                        fullWidth
                                        onClick={sendEmails}
                                    >
                                        Send Emails
                                    </Button>
                                </div>
                            </>
                        )}

                    </Paper>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Paper sx={{ mt: 0, mb: 0, p: 2 }}>
                        <h3>Participants</h3>
                        {event.participants && <ParticipantTable participants={event.participants} />}
                    </Paper>
                </Grid>
            </Grid>
        </Container >
    )
}
