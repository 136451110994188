import { Card, CardActionArea, CardContent, CardHeader, Divider, Typography } from '@mui/material';
import { SwapEvent } from '../../app/models/SwapEvent';

type Props = {
    event: SwapEvent;
};

export default function EventCard({ event }: Props) {

    const isValidDate = (d: any): d is Date => {
        return d instanceof Date && !isNaN(d.getTime());
    };

    const formatDateWithTime = (date: Date | string) => {
        let d = (typeof date === 'string') ? new Date(date) : date;

        if (!isValidDate(d)) {
            console.error('Invalid date object:', date);
            return 'Invalid Date';
        }

        const dateOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
        const timeOptions: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit', hour12: true };

        return `${d.toLocaleDateString('en-US', dateOptions)} at ${d.toLocaleTimeString('en-US', timeOptions)}`;
    };

    const formatPrice = (price: number) => {
        return `$${(price / 100).toFixed(2)}`;
    };

    return (
        <Card>
            <CardActionArea component="a" href={`/events/${event.id}`}>
                <CardHeader
                    title={event.name}
                />
                <CardContent>
                    <Typography variant="body2" gutterBottom>
                        Deadline: {formatDateWithTime(event.registrationDeadline)}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        Price Limit: {formatPrice(event.priceLimit)}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        Participants: {event.participants?.length || 0}
                    </Typography>
                    <Divider style={{ margin: '16px 0' }} />

                    {event.emailsSent ? (
                        <Typography variant="body2" gutterBottom>
                            Emails Sent: {formatDateWithTime(event.emailsSent)}
                        </Typography>
                    ) : event.isClosed ? (
                        <Typography variant="body2" gutterBottom>
                            Registration is closed
                        </Typography>
                    ) : (
                        <Typography variant="body2" gutterBottom>
                            Registration is still open
                        </Typography>
                    )}

                </CardContent>
            </CardActionArea>
        </Card>
    );
}
