import { LoadingButton } from '@mui/lab';
import { Box, Button, Paper, Step, StepLabel, Stepper, Typography } from '@mui/material';
import axios from 'axios';
import { useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import Complete from './Complete';
import ConfirmEmailForm from './ConfirmEmailForm';
import RegisterForm from './RegisterForm';

const steps = ['Register', 'Confirm Email', 'Complete'];

function getStepContent(step: number) {
    switch (step) {
        case 0: return <RegisterForm />
        case 1: return <ConfirmEmailForm />
        case 2: return <Complete />
        default:
            throw new Error('Unknown Step');
    }
}

export default function Register() {

    const { id } = useParams();
    const [activeStep, setActiveStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [links, setLinks] = useState<any>({});
    const [error, setError] = useState<string | null>(null);

    const methods = useForm({ mode: 'all' });

    const handleNext = async (data: FieldValues) => {
        if (activeStep === 0) {
            setLoading(true);
            const { firstName, lastName, email } = data;
            axios.post(`/api/giftswap/events/${id}/participants`, { firstName, lastName, email })
                .then(response => {
                    console.log(response.data);
                    setLinks(response.data.links);
                    setActiveStep(1);
                    console.log(response.data.links);
                    setError(null);
                })
                .catch(error => {
                    console.log(error);
                    setError(error.response.data.title || 'An unexpected error occurred.');
                })
                .finally(() => setLoading(false));

        } else if (activeStep === 1) {
            setLoading(true);
            console.log(links);
            const { validate } = links;
            const { otp } = data;
            axios.get(`${validate}?otp=${otp}`)
                .then(() => {
                    setActiveStep(2);
                    setError(null);
                })
                .catch(error => {
                    console.log(error);
                    setError(error.response.data.title || 'An unexpected error occurred.');
                })
                .finally(() => setLoading(false));
        }
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    return (
        <FormProvider {...methods}>
            <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                <Typography component="h1" variant="h4" align="center">
                    Sign up
                </Typography>
                <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>

                {error && (
                    <Typography
                        component="p"
                        variant="body2"
                        color="error"
                        style={{
                            textAlign: 'center',      // Center the text
                            marginBottom: '20px',    // Add margin to the bottom
                            position: 'relative',    // Needed to adjust the position
                            top: '-10px'             // Move the element 10 pixels up
                        }}>
                        {error}
                    </Typography>
                )}

                <form onSubmit={methods.handleSubmit(handleNext)}>
                    {getStepContent(activeStep)}
                    {activeStep < 2 && (
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {activeStep !== 0 && (
                                <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                                    Back
                                </Button>
                            )}
                            <LoadingButton
                                loading={loading}
                                // disabled={!methods.formState.isValid}
                                variant="contained"
                                type='submit'
                                sx={{ mt: 3, ml: 1 }}
                            >
                                Next
                            </LoadingButton>
                        </Box>
                    )}
                </form>
            </Paper>
        </FormProvider>
    )
}
